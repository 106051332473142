import { createRouter, createWebHashHistory } from 'vue-router';
import pathLoader from '../plugins/loader'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'homePrivate',
        component: pathLoader(null, 'homePrivate'),
        redirect: 'dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: pathLoader('dashboard'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/utilisateurs',
                name: 'utilisateurs',
                component: pathLoader('utilisateurs'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/commerce',
                name: 'commerce',
                component: pathLoader('commerce'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/demandes',
                name: 'demandes',
                component: pathLoader('demandes'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/commerceDetail',
                name: 'commerceDetail',
                component: pathLoader('commerceDetail'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/clients',
                name: 'clients',
                component: pathLoader('clients'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/fournisseurs',
                name: 'fournisseurs',
                component: pathLoader('fournisseurs'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/collaborateurs',
                name: 'collaborateurs',
                component: pathLoader('collaborateurs'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/partenaires',
                name: 'partenaires',
                component: pathLoader('partenaires'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/nouveauSfd',
                name: 'nouveauSfd',
                component: pathLoader('nouveauSfd'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/commerciaux',
                name: 'commerciaux',
                component: pathLoader('commerciaux'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/nouveauCommercial',
                name: 'nouveauCommercial',
                component: pathLoader('nouveauCommercial'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/commercialDetail',
                name: 'commercialDetail',
                component: pathLoader('commercialDetail'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/charteredAccountants',
                name: 'charteredAccountants',
                component: pathLoader('charteredAccountants'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/coupons',
                name: 'coupons',
                component: pathLoader('coupons'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/droits',
                name: 'droits',
                component: pathLoader('droits'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/access',
                name: 'access',
                component: pathLoader('access'),
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },

    {
        path: '/login',
        name: 'login',
        component: pathLoader('login'),
    },
];

const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.token) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router;
