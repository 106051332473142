import { createApp } from 'vue'
import App from './App.vue'
import rooter from '../src/router/index'
import install from './install'
import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'
import 'material-icons/iconfont/material-icons.css'
import i18n from './i18n'
import vuex from "./store"
import easySpinner from 'vue-easy-spinner'
import Switches from 'vue-switches'
import vClickOutside from "click-outside-vue3"
import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'
import VueClipboard from 'vue3-clipboard'
import excel from 'vue-excel-export'
import VueApexCharts from "vue3-apexcharts";
import Toaster from "@meforma/vue-toaster";

createApp(App)
    .use(i18n)
    .use(vuex)
    .use(excel)
    .use(VueApexCharts)
    .use(easySpinner, {
        /*options*/
        prefix: 'easy',
    })
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    })
    .use(VCalendar, {})
    .use(vClickOutside)
    .use(Switches)
    .use(VueSvgIconPlugin, { tagName: 'icon' })
    .use(Toaster)
    .use(install)
    .use(rooter)
    .mount('#app')
